import { gql, useQuery } from "@apollo/client";
import { AnimatePresence, m, LazyMotion } from "framer-motion";
import { PetCard } from "../PetCard";
import { Animal, Cat, Page_Flexiblecontent_Blocks_PetCarousel } from "graphql";
import { useEffect, useState } from "react";
import useWindowWidth from "utilities/useWindowWidth";
import Arrow from "public/arrow.svg";
import { Button } from "components/Button";
const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);
export interface PetCarouselProps
  extends Page_Flexiblecontent_Blocks_PetCarousel {
  className?: string;
}

const GET_VIRTUAL_FOSTER_ANIMALS = gql`
  query GetVirtualFosterAnimals($count: Int!) {
    virtualFosterAnimals(count: $count) {
      id
      title
      uri
      content
      animalDetails {
        animalName
        animalNid
        animalBirthday
        animalSex
        animalUniqueId
        animalWeight
        animalIntakeDate
        animalDescription
        animalPhotoGallery {
          photo
        }
        animalCoverPhoto
      }
      petAttributes {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      primaryBreeds {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      secondaryBreeds {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      weightGroups {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      ageGroups {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
    }
  }
`;


const GET_RANDOM_ANIMALS = gql`
  query GetRandomAnimals($count: Int!) {
    randomAnimals(count: $count) {
      id
      title
      uri
      content
      animalDetails {
        animalName
        animalNid
        animalBirthday
        animalSex
        animalUniqueId
        animalWeight
        animalIntakeDate
        animalDescription
        animalPhotoGallery {
          photo
        }
        animalCoverPhoto
      }
      petAttributes {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      primaryBreeds {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      secondaryBreeds {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      weightGroups {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
      ageGroups {
        nodes {
          slug
          termTaxonomyId
          name
        }
      }
    }
  }
`;

const PetCarousel = ({
  className,
  variant,
  arrowsPlacement,
  title,
  displayRandomDogs,
  displayVirtualFosterDogs,
  pets: customPets,
  cta,
  buttonStyle,
}: PetCarouselProps) => {
  const [pets, setPets] = useState<(Animal | Cat)[]>([]);
  const [currentCarouselPage, setCurrentCarouselPage] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(1);
  const hasPets =
    displayVirtualFosterDogs || displayRandomDogs ||
    (customPets && customPets.length > 0);
  const windowWidth = useWindowWidth();
  const isFeatured = variant === "featured";

  const arrsBase = arrowsPlacement && arrowsPlacement === "base";
  const arrsTop = arrowsPlacement && arrowsPlacement === "arr-above";
  const arrsBottom = arrowsPlacement && arrowsPlacement === "arr-below";

  const { loading, error, data } = useQuery(GET_RANDOM_ANIMALS, {
    variables: { count: 8, disableCache: true },
    skip: !displayRandomDogs,
    fetchPolicy: "network-only",
  });

  const { loading: loadingVirtual, error: errorVirtual, data: dataVirtual } =
    useQuery(GET_VIRTUAL_FOSTER_ANIMALS, {
      variables: { count: 12, disableCache: true },
      skip: !displayVirtualFosterDogs,
      fetchPolicy: "network-only",
    });

  function handleResize() {
    if (!isFeatured) {
      if (windowWidth.width !== undefined && windowWidth.width <= 768) {
        setSlidesPerView(2);
      } else if (windowWidth.width !== undefined && windowWidth.width < 1024) {
        setSlidesPerView(2);
      } else if (windowWidth.width !== undefined && windowWidth.width <= 1279) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
      }
    }
  }

  const carouselContainer = isFeatured
    ? `variant-${variant} ${className} relative px-2 bg-primary-light`
    : `variant-${variant} ${className} relative px-2 my-10`;

  const carouselWrapper = isFeatured
    ? `grid grid-cols-${slidesPerView} gap-6 relative w-full pt-8 md:pt-0 md:px-20`
    : `grid grid-cols-${slidesPerView} gap-6 relative w-full pb-5 md:px-20 md:pb-0 h-fit`;
    

  const handleNext = () => {
    if (pets?.length && currentCarouselPage < pets.length - slidesPerView) {
      setCurrentCarouselPage(currentCarouselPage + slidesPerView);
    } else {
      setCurrentCarouselPage(0);
    }
  };

  const handlePrev = () => {
    if (currentCarouselPage > 0) {
      setCurrentCarouselPage(currentCarouselPage - slidesPerView);
    } else {
      setCurrentCarouselPage((pets?.length ?? 0) - slidesPerView);
    }
  };

  useEffect(() => {
    handleResize();
  }, [windowWidth.width]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentCarouselPage]);

  useEffect(() => {
    if ((displayRandomDogs && data && data.randomAnimals) || (displayVirtualFosterDogs && dataVirtual && dataVirtual.virtualFosterAnimals)) {
      setPets( (displayRandomDogs ? data?.randomAnimals : dataVirtual?.virtualFosterAnimals) as Animal[]);
    } else {
      if (!loading && !error) {
        setPets(customPets as Animal[]);
      }
    }
  }, [data, customPets, displayRandomDogs, displayVirtualFosterDogs, dataVirtual]);

  return (
    <div id="petCarousel" className={carouselContainer}>
      <div className={arrsTop ? "flex flex-row justify-center items-center mb-6 md:mb-10 max-w-[1320px] mx-auto gap-10" : "mb-6 md:mb-10 max-w-[1320px] mx-auto"}>
        {title ? (
          <h3
            className={arrsTop ? `text-center font-heading text-2xl md:text-4xl shrink-0` : `text-center font-heading text-2xl md:text-6xl`}
          >
            {title}
          </h3>
        ) : null}
        {arrsTop && (
          <>
            <div className={`grow h-[1px] bg-dark w-full`}></div>
            <div className={`flex justify-center space-x-4`}>
              <button
                onClick={handlePrev}
                className={`grid h-10 w-10 place-items-center rounded-full bg-white`}
                aria-label="Previous Slide"
              >
                <Arrow
                  className={`h-auto w-10`}
                  style={{ transform: "rotate(180deg)" }}
                />
              </button>
              <button
                onClick={handleNext}
                className={`grid h-10 w-10 place-items-center rounded-full bg-white`}
                aria-label="Next Slide"
              >
                <Arrow className={`h-auto w-10`} />
              </button>
            </div>
          </>
        )}
      </div>
      <div
        className={`container relative mx-auto flex flex-col md:flex-row md:px-4`}
      >
        {arrsBase && (
          <button
            onClick={handlePrev}
            className={`absolute left-0 top-1/2 z-10 grid h-10 w-10 place-items-center rounded-full bg-white md:bottom-auto md:right-0 md:top-0 md:h-full md:w-fit md:bg-transparent`}
            aria-label="Previous Slide"
          >
            <Arrow
              className={`h-auto w-8 md:w-20`}
              style={{ transform: "rotate(180deg)" }}
            />
          </button>
        )}
        
        <div className={carouselWrapper}>
          <LazyMotion features={loadFeatures}>
            <AnimatePresence initial={false} mode="popLayout">
              {hasPets &&
                pets?.map((pet, index) => {
                  let active =
                    index >= currentCarouselPage &&
                    index < currentCarouselPage + slidesPerView;
                  return (
                    <m.div
                      key={pet?.id}
                      initial={{
                        opacity: 0,
                        scale: 0.9,
                      }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        delay: 0.5,
                        layout: {
                          type: "spring",
                          bounce: 0.5,
                          duration: 0.5,
                        },
                      }}
                      className={`${active ? "flex " : "hidden"}`}
                    >
                      <PetCard
                        variant={variant ? variant : "basic"}
                        pet={pet as Animal}
                        i={index}
                        isFosterable={displayVirtualFosterDogs ?? false}
                      />
                    </m.div>
                  );
                })}
            </AnimatePresence>
          </LazyMotion>
        </div>
        {arrsBase && (
          <button
            onClick={handleNext}
            className={`absolute right-0 top-1/2 z-10 grid h-10 w-10 place-items-center rounded-full bg-white md:bottom-auto md:right-0 md:top-0 md:h-full md:w-fit md:bg-transparent`}
            aria-label="Next Slide"
          >
            <Arrow className={`h-auto w-8 md:w-20`} />
          </button>
        )}
      </div>
      {arrsBottom && (
        <div className={`flex flex-row justify-center items-center mt-6 md:mt-10 max-w-[1320px] mx-auto gap-10`}>
          <div className={`grow h-[1px] bg-dark w-full`}></div>
          <div className={`flex justify-center space-x-4`}>
            <button
              onClick={handlePrev}
              className={`grid h-10 w-10 place-items-center rounded-full bg-white`}
              aria-label="Previous Slide"
            >
              <Arrow
                className={`h-auto w-10`}
                style={{ transform: "rotate(180deg)" }}
              />
            </button>
            <button
              onClick={handleNext}
              className={`grid h-10 w-10 place-items-center rounded-full bg-white`}
              aria-label="Next Slide"
            >
              <Arrow className={`h-auto w-10`} />
            </button>
          </div>
        </div>
      )}
      {cta ? (
        <div className={`mt-10 text-center`}>
          <Button
            href={cta?.url ?? "/"}
            variant={buttonStyle as string}
            target={cta?.target ?? "_self"}
          >
            {cta?.title ?? "View All"}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default PetCarousel;
